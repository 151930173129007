export const config = {
    RPC_URL: 'https://rpc.bitcanna.io',
    REST_URL: 'https://lcd.bitcanna.io',
    EXPLORER_URL: 'https://explorer.bitcanna.io',
    STAKING_URL: 'https://wallet.bitcanna.io',
    NETWORK_NAME: 'BitCanna',
    NETWORK_TYPE: 'mainnet',
    CHAIN_ID: 'bitcanna-1',
    CHAIN_NAME: 'BitCanna-1',
    COIN_DENOM: 'BCNA',
    COIN_MINIMAL_DENOM: 'ubcna',
    COIN_DECIMALS: 6,
    PREFIX: 'bcna',
    COIN_TYPE: 118,
    COSMOSTAION: 'bitcanna',
    COINGECKO_ID: 'bitcanna',
    GAS_PRICE_STEP_LOW: 0.001,
    GAS_PRICE_STEP_AVERAGE: 0.01,
    GAS_PRICE_STEP_HIGH: 0.05,
    FEATURES: ['ibc-transfer', 'ibc-go'],
};
